import ErrorPageContent from "@components/ErrorPageContent";
import {useTheme} from 'styled-components';

export default function Error500() {
  const { key } = useTheme();

  let errorProps = {
    errorNumber: 500,
    title: '',
    subhead: '',
    body: ''
  };

  const zslErrorProps = {
    errorNumber: 500,
    title: 'Looks like something went wrong',
    subhead: 'Error 500 internal server error',
    body: 'We’re sorry, there may be a technical problem with this page. When things go wrong for wildlife, our conservationists and scientists are committed to helping endangered species recover. Try our useful links below to try and get you back on track, or donate what you can to help our important work.',
  };

  const otherErrorProps = {
    errorNumber: 500,
    title: 'Error 500. Internal server error.',
    subhead: '',
    body: 'We’re sorry, there may be a technical problem with this page. When things go wrong for wildlife, our conservationists and scientists are committed to helping endangered species recover. Try our useful links below to try and get you back on track, or donate what you can to help our important work.',
  };

  switch (key) {
    case 'london':
    case 'whipsnade':
      errorProps = otherErrorProps;
      break;
    default:
      errorProps = zslErrorProps;
      break;
  }

  return (
    <main>
      <ErrorPageContent {...errorProps} />
    </main>
  );
}
