import styled, {useTheme} from 'styled-components';
import {ReactElement} from 'react';
import {colors, mediaQueries} from '@/theme/lib';
import {useMobile} from '@lib/hooks/useMobile';
import Error403Desktop from './assets/403--desktop.png';
import Error403Mobile from './assets/403--mobile.png';
import Error403London from './assets/403-london.png';
import Error403LondonMobile from './assets/403-london--mobile.png';
import Error403Whipsnade from './assets/403-whipsnade.png';
import Error403WhipsnadeMobile from './assets/403-whipsnade--mobile.png';
import Error404Mobile from './assets/404--mobile.png';
import Error404Desktop from './assets/404--desktop.png';
import Error404London from './assets/404-london.png';
import Error404Whipsnade from './assets/404-whipsnade.png';
import Error500Desktop from './assets/500--desktop.png';
import Error500Mobile from './assets/500--mobile.png';
import Error500London from './assets/500-london.png';
import Error500LondonMobile from './assets/500-london--mobile.png';
import Error500Whipsnade from './assets/500-whipsnade.png';
import Error500WhipsnadeMobile from './assets/500-whipsnade--mobile.png';
import Image from 'next/image';
import Link from 'next/link';
import {themes} from "@/theme";

export type ErrorPageContentProps = {
  errorNumber: number;
  title: string;
  subhead: string;
  body: string;
};

const StyledErrorPageContent = styled.div<{ errorNumber: number, key: string }>`
  ${mediaQueries.md} {
    ${(props) => props.errorNumber === 500 ?
    `
      display: flex;
      align-itens: center;
      height: 100vh;
    ` : `
    `}
  }

  .error-page-content {
    position: relative;
    margin: 7.905rem auto 0;
    display: grid;
    grid-template-columns: repeat(5, [col-start] 1fr);
    align-items: flex-end;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 1528px;
    ${(props) => props.theme.key === 'zsl' ?
      `
        margin-bottom: -10rem;
      ` : `
        margin-bottom: -3.5rem;
    `}


    ${mediaQueries.md} {
      grid-template-columns: repeat(10, [col-start] 1fr);

      ${(props) => props.theme.key === 'zsl' ?
      `
        margin-top: 12rem;
        margin-bottom: -12rem;
      ` : `
        margin-top: 15rem;
        margin-bottom: -5.5rem;
      `}

      ${(props) => props.errorNumber === 500 ?
      `
        align-items: center;
        margin-top: 5rem;
        margin-bottom: 0;
      ` : `
      `}
    }

    ${mediaQueries.lg} {
      ${(props) => props.theme.key === 'zsl' ?
      `
        align-items: flex-start;
      ` : `
        align-items: center;

        ${props.errorNumber === 403 ?
        `
          margin-top: 18rem;
          margin-bottom: 0rem;
        ` : `
        `}

        ${props.errorNumber === 404 ?
        `
          margin-top: 12rem;
          margin-bottom: -8rem;
        ` : `
        `}
      `}
      ${(props) => props.errorNumber === 500 ?
      `
        margin-top: 10rem;
        align-items: center;
      ` : `
      `}
    }

    ${mediaQueries.xl} {
      ${(props) => props.theme.key === 'zsl' ?
      `
        ${props.errorNumber === 500 ?
        `
          margin-top: 0rem;
          margin-bottom: -10rem;
        ` : `
        `}

      ` : `
        ${props.errorNumber === 403 ?
        `
          margin-top: 24rem;
          margin-bottom: 0rem;
        ` : `
        `}

        ${props.errorNumber === 404 ?
        `
          margin-top: 16rem;
          margin-bottom: -8rem;
        ` : `
        `}
      `}
    }

    &__wrapper {
      position: relative;
      grid-column: span 5;

      ${mediaQueries.md} {
        ${(props) => props.theme.key === 'zsl' ? `
          grid-row-start: 1;
          grid-column: 1 / span 4;
          margin-bottom: 6rem;
        ` : `
          grid-column: 7 / span 5;
        `}
      }

      ${mediaQueries.lg} {
        ${(props) => props.theme.key === 'zsl' ? `
        ` : `
          grid-column: 7 / span 4;
        `}
      }
    }

    &__title {
      color: ${(props) => props.theme.key === 'zsl' ? colors.white : colors.zooFont};
      text-align: center;
      line-height: 105%;

      ${(props) => props.theme.key === 'zsl' ?
      `
        font-size: 2.5rem;
      ` : `
        font-size: 2rem;
      `}

      ${mediaQueries.md} {
        text-align: left;
        ${(props) => props.theme.key === 'zsl' ?
        `
          font-size: 5rem;
        ` : `
          font-size: 3rem;
        `}
        ${(props) => props.errorNumber === 403 ?
        `
          width: 430px;
        ` : `
        `}
      }
    }

    &__subhead {
      color: ${(props) => props.theme.key === 'zsl' ? colors.white : colors.zooFont};
      font-size: 1rem;
      line-height: 120%;
      font-weight: 700;
      margin-top: 1.25rem;
      text-align: center;

      ${mediaQueries.md} {
        font-size: 1.25rem;
        margin-top: 1.75rem;
        text-align: left;
      }
    }

    &__body {
      color: ${(props) => props.theme.key === 'zsl' ? colors.white : colors.zooFont};
      font-size: 1rem;
      line-height: 140%;
      font-style: bold;
      margin-top: 0.25rem;
      text-align: center;
      white-space: pre-wrap;

      ${mediaQueries.md} {
        font-size: 1.125rem;
        margin-top: 0.375rem;
        text-align: left;
      }
    }

    &__links-list {
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 0.75rem;

      ${mediaQueries.md} {
        margin-top: 1rem;
        justify-content: start;
      }
    }

    &__links-item {
      display: inline-block;
      margin-right: 1.4375rem;

      &:last-child {
        margin-right: 0;
      }
    }

    &__link {
      ${(props) => props.theme.key === 'zsl' ? `color: ${colors.parakeet};` : ``};
      ${(props) => props.theme.key === 'london' ? `color: ${colors.seagrass};` : ``};
      ${(props) => props.theme.key === 'whipsnade' ? `color: ${colors.redPanda};` : ``};
      font-size: 1rem;
      line-height: 120%;

      ${(props) => props.theme.key === 'zsl' ? `
        border: 0;
      ` : `
        text-decoration-line: none;
      `};

      &:hover, &:focus {
        ${(props) => props.theme.key === 'zsl' ? `
          border-bottom: solid 1px ${colors.parakeet};
          text-decoration: none;
        ` : `
          text-decoration: underline;
        `};
      }

      ${mediaQueries.md} {
        font-size: 1.125rem;
      }
    }

    &__image-block {
      position: relative;
      grid-column: span 5;

      ${(props) => props.theme.key === 'zsl' ? `
        margin-top: 4.375rem;
      ` : `
        grid-row-start: 1;
        margin-bottom: 3.125rem;
      `}

      ${mediaQueries.md} {
        ${(props) => props.theme.key === 'zsl' ? `
          grid-row-start: 1;
          grid-column: 6 / span 5;
          margin: 0;
        ` : `
          grid-column: span 5;
        `}
      }
    }

    &__image-wrapper {
      position: relative;

      ${mediaQueries.lg} {
        ${(props) => props.theme.key === 'zsl' ? `
          min-width: calc(50vw - 10px);
        ` : `
        `}
      }

      img {
        position: relative;
        display: block;
        width: 100%;
      }
    }

    &__error-background {
      position: absolute;
      z-index: 0;
      width: 100%;
      top: auto;
      bottom: 0;
    }
  }
`;

function ErrorImageBlock(theme: keyof typeof themes, error: number, mobile: boolean): ReactElement {
  switch (error) {
    case 403:
      return (
        <Image
          src={theme === 'zsl' ? mobile ? Error403Mobile : Error403Desktop : theme === 'london' ? mobile ? Error403LondonMobile : Error403London : mobile ? Error403WhipsnadeMobile : Error403Whipsnade}
          alt={'403 forbidden'}
          layout="intrinsic"
          width={theme === 'zsl' ? mobile ? 367 : 952 : mobile ? 274 : 701 }
          height={theme === 'zsl' ? mobile ? 280 : 863 : mobile ? 240 : 311}
        />
      );

    case 404:
      return (
        <Image
          src={theme === 'zsl' ? mobile ? Error404Mobile : Error404Desktop : theme === 'london' ? Error404London : Error404Whipsnade}
          alt={'404 not found'}
          layout="intrinsic"
          width={theme === 'zsl' ? mobile ? 360 : 941 : mobile ? 321 : 841}
          height={theme === 'zsl' ? mobile ? 300 : 771 : mobile ? 272 : 655}
        />
      );
    default:
      return (
        <Image
          src={theme === 'zsl' ? mobile ? Error500Mobile : Error500Desktop : theme === 'london' ? mobile ? Error500LondonMobile : Error500London : mobile ? Error500WhipsnadeMobile : Error500Whipsnade}
          alt={'500 internal server error '}
          layout="intrinsic"
          width={theme === 'zsl' ? mobile ? 370 : 944 : mobile ? 287 : 744}
          height={theme === 'zsl' ? mobile ? 188 : 723 : mobile ? 233 : 418}
        />
      );
  }
}

type errorLinkProps = {
  label: string;
  url: string;
}
const getErrorLinks = (theme: keyof typeof themes): Array<errorLinkProps> => {
  switch (theme) {
    default:
    case 'zsl':
      return [
        {
          label: 'Home',
          url: '/',
        },
        {
          label: 'Search',
          url: '/search',
        },
        {
          label: 'Donate',
          url: 'https://donate.zsl.org/donations/',
        },
        {
          label: 'Contact',
          url: '/about-zsl/contact-zsl',
        },
      ];
    case 'london':
      return [
        {
          label: 'Home',
          url: '/',
        },
        {
          label: 'Search',
          url: '/search',
        },
        {
          label: 'Buy tickets',
          url: 'https://www.londonzoo.org/plan-your-visit/london-zoo-tickets',
        },
        {
          label: 'Contact',
          url: '/about-us/contact-london-zoo',
        },
      ];
    case 'whipsnade':
      return [
        {
          label: 'Home',
          url: '/',
        },
        {
          label: 'Search',
          url: '/search',
        },
        {
          label: 'Buy tickets',
          url: 'https://zslwhipsnadezoo.seetickets.com/zsl/zsl-whipsnade-zoo',
        },
        {
          label: 'Contact',
          url: '/about-us/contact-whipsnade-zoo',
        },
      ];
  }
  return [];
}

export default function ErrorPageContent({
  title,
  errorNumber,
  subhead,
  body,
}: ErrorPageContentProps): ReactElement {
  const { key } = useTheme();
  const isMobile = useMobile();
  const links = getErrorLinks(key as keyof typeof themes);

  return (
    <StyledErrorPageContent key={key} errorNumber={errorNumber}>
      <div className="error-page-content">
        <div className="error-page-content__wrapper">
          <div className="error-page-content__text-block">
            {title && <h1 className="error-page-content__title">{title}</h1>}
            {subhead && <p className="error-page-content__subhead">{subhead}</p>}
            <p className="error-page-content__body" dangerouslySetInnerHTML={{ __html: body }} />
            <ul className="error-page-content__links-list">
              {links.map(({url, label}, key) =>
                <li key={`error-link-${key}`} className="error-page-content__links-item">
                  <Link href={url}>
                    <a className="error-page-content__link">{label}</a>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="error-page-content__image-block">
          <div className="error-page-content__image-wrapper">
            {ErrorImageBlock(key as keyof typeof themes, errorNumber, isMobile)}
          </div>
        </div>
      </div>
    </StyledErrorPageContent>
  );
}
